<template>
  <div class="e-form e-form--account">
    <div class="e-module e-module--small">
      <!-- Title -->
      <div class="e-form__title">
        <h1>{{ $t('system.navigation.gdpr') }}</h1>
      </div>
      <div class="e-form__content">
        <p>Um den aktuellen Vorschriften der Datenschutzgrundverordnung gerecht zu werden, haben wir einige Anpassungen bei der Speicherung Deiner Daten vorgenommen und unsere Datenschutzerklärung daraufhin überarbeitet.</p>
        <p>So können Alter, Geschlecht und E-Mail-Adresse nun freiwillig angeben werden. Bitte beachte aber, dass ohne die Angabe einer E-Mail-Adresse die Funktionen „Passwort vergessen“, „Benutzername vergessen“ sowie „per E-Mail über eine neue Antwort benachrichtigt werden“ nicht genutzt werden können.</p>
        <p>
          Wir möchten Dich bitten, unsere
          <router-link :to="{ name: 'content', params: { contentName: 'privacy' } }">
            {{ $t('system.privacy.title') }}
          </router-link>
          (Stand {{ gdprVersion }}) zu lesen und Deine Einwilligung zur Speicherung Deiner Daten zur Nutzung der Online-Beratung zu erneuern. Nur mit Deiner Einwilligung kannst Du die Online-Beratung - Cybermobbing-Hilfe e.V. weiter nutzen.
        </p>
        <div class="radio-form-item">
          <input
            id="one"
            v-model="radio"
            type="radio"
            value="1"
          >
          <label for="one">
            Ja, ich habe die neue Datenschutzerklärung gelesen und möchte die em@il-Beratung weiter nutzen. Hiermit erneuere ich die Einwilligung zur Speicherung meiner Daten.
          </label>
        </div>
        <div class="radio-form-item">
          <input
            id="two"
            v-model="radio"
            type="radio"
            value="0"
          >
          <label for="two">
            Nein, ich stimme der neuen Datenschutzerklärung nicht zu. Mein Nutzerkonto bitte unwiderruflich löschen.
          </label>
        </div>
        <div class="e-form__row e-form__row--submit">
          <el-button style="visibility:hidden" />
          <el-button
            :disabled="disabled"
            :loading="loading"
            native-type="submit"
            type="tertiary"
            @click="gdprClick"
          >
            Absenden
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { userActions } from 'mixins'

export default {
  mixins: [userActions],
  data () {
    return {
      loading: false,
      radio: -1
    }
  },
  computed: {
    gdprVersion () {
      return format(window.gdprVersion, 'dd.MM.yyyy')
    },
    disabled () {
      return this.radio === -1
    }
  },
  methods: {
    async gdprClick () {
      if (this.radio === '0') {
        this.$confirm(this.$t('messages.user.remove.confirm'), this.$t('system.warning'), {
          confirmButtonText: this.$t('system.confirm'),
          cancelButtonText: this.$t('system.cancel'),
          type: 'warning'
        }).then(async () => {
          await this.__user__deleteAccount()
          this.__user__logout()
        })
        return
      }
      await this.__user__confirmGdpr()
      this.$router.push({ name: 'dialogs' })
    }
  }
}
</script>

<style lang="scss">
.radio-form-item {
  display: flex;
  margin-bottom: 20px;
  input {
    width: 16px;
    min-width: 16px;
  }

  label {
    margin-left: 15px;
  }
}
</style>
